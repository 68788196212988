
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$SJC-Youth-primary: mat.define-palette(mat.$indigo-palette);
$SJC-Youth-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$SJC-Youth-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$SJC-Youth-theme: mat.define-light-theme((
  color: (
    primary: $SJC-Youth-primary,
    accent: $SJC-Youth-accent,
    warn: $SJC-Youth-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($SJC-Youth-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./scss/base/module";
@import "./scss/components/module";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

html, body { height: 100%; }

.mat-mdc-snack-bar-container.success-snackbar{
  --mdc-snackbar-container-color: #3E5B6B !important;
  border-radius: 4px;
}
.mat-mdc-snack-bar-container.error-snackbar{
  --mdc-snackbar-container-color: #602020 !important;
  border-radius: 4px;
}

.mat-mdc-snack-bar-container.info-snackbar{
  --mdc-snackbar-container-color: #4D4D4D !important;
  border-radius: 4px;
}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
