@tailwind components;

@layer components
{
  .card-school {
    @apply transition-all text-left rounded-lg text-black bg-white border border-black min-h-72 sm:w-64 w-11/12 text-center;
  }

  .card-school img {
    @apply rounded-t-lg;
  }

  .card-school > .img-content > img {
    @apply h-full object-cover w-full;
  }

  .card-school > .img-content {
    @apply relative overflow-hidden h-36;
  }

  .card-school > .no-img {
    @apply w-full bg-grey relative overflow-hidden h-36 rounded-t-lg;
  }

  .card-school-district-page {
    @apply transition-all text-left rounded-lg text-black bg-white border border-black min-h-72 sm:w-64 w-11/12 text-center;
  }

  .card-article {
    @apply transition-all text-left rounded-lg text-black sm:w-64 xl:w-72 w-11/12 min-h-96;
  }

  .card-article-white {
    @apply transition-all text-left rounded-lg border-grey border bg-white text-black sm:w-64 xl:w-72 w-11/12 min-h-96;
  }

  .card-article > .img-content > img {
    @apply h-full object-cover w-full rounded-t-lg;
  }

  .card-article > .img-content {
    @apply relative overflow-hidden h-36 rounded-t-lg;
  }

  .card-article > .no-img {
    @apply w-full bg-grey relative overflow-hidden h-36;
  }

  .card-article-landing {
    @apply transition-all text-left rounded-t-lg rounded-lg text-black sm:w-60 w-11/12 min-h-96;
  }

  .card-article-landing > .img-content > img {
    @apply h-full object-cover w-full rounded-t-lg;
  }

  .card-article-landing > .img-content {
    @apply relative overflow-hidden h-36;
  }

  .card-article-landing > .no-img {
    @apply w-full bg-grey relative overflow-hidden h-36;
  }

  .card-program {
    @apply mx-auto rounded-lg transition-all text-black sm:w-88 w-11/12 min-h-108;
  }

  .card-program img {
    @apply sm:rounded-t-lg;
  }

  .card-program > .img-content > img {
    @apply h-full object-cover;
  }

  .card-program > .img-content {
    @apply relative overflow-hidden h-64;
  }

  .card-podcast {
    @apply transition-all text-left pb-4 sm:w-64 w-11/12 h-72;
  }

  .card-podcast > .img-content {
    @apply relative overflow-hidden h-32;
  }

  .card-quote {
    @apply transition-all sm:rounded-lg pb-4;
  }

  .card-school > .img-content > img {
    @apply rounded-t-lg;
  }
}
